<script>
import WebauthnButton from "~/components/webauthn/WebauthnButton.svelte";
import localizer from "~/common/i18n.js";
import {performLoginCeremony} from "~/common/webAuthn.js";

export let prio = false;
export let postbackErrorUrl = null
export let username;

const t = localizer({
    nb: {
        title: 'Logg inn uten passord',
    },
    nn: {
        title: 'Logg inn utan passord',
    }
})

</script>

<WebauthnButton prio={prio} logName="login_webauthn" on:click={(e) => {e.preventDefault(); performLoginCeremony(postbackErrorUrl, username);}} >{t('title')}</WebauthnButton>

<style>
</style>
