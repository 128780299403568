<script>
    import {createEventDispatcher} from "svelte";

    export let title;
    export let text;
    export let cta;
    export let ctaUrl;

    let dispatch = createEventDispatcher();
</script>

<div class="container">
    <div class="pointer"></div>
    <div class="bubble">
        {#if title}<h1>{title}</h1>{/if}
        {#if text}
            <p>{text}</p>
        {/if}
        {#if cta}
            <a href="{ctaUrl ? ctaUrl : ''}" on:click={() => dispatch('cta')}>{cta}</a>
        {/if}
    </div>
</div>

<style>
    @import "../common/colors.css";

    .container {
        margin-top: -17px;
    }

    .container .pointer {
        box-sizing: border-box;
        width: 30px;
        height: 15px;
        margin-left: 75px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #FBBC0529;
    }

    .container .bubble {
        background-color: #FBBC0529;
        padding: 15px;
        font-size: 86%;
    }

    h1 {
        color: var(--brownOnYellow);
        font-size: 120%;
        margin-bottom: 8px;
    }

    p {
        color: var(--brownOnYellow);
        margin-bottom: 8px;
    }

    a {
        color: var(--brownOnYellow);
        font-weight: bold;
        cursor: pointer;
    }

</style>