<script>
    import ExpansionBox from "~/components/profile/ExpansionBox.svelte";
    import localizer from "~/common/i18n.js";
    import SessionsList from "~/components/SessionsList.svelte";

    export let sessions = [];
    export let maxSessions;
    export let requestedUrl;


    const t = localizer({
        nb: {
            mySessions: 'Se mine innlogginger ({{num}})'
        },
        nn: {
            mySessions: 'Sjå mine innloggingar ({{num}})'
        }
    });
</script>

<ExpansionBox padding={false} theme="narrow" headerText={t('mySessions', { num: sessions.length })}>
    <SessionsList {sessions} embeddedMode={true} {maxSessions} {requestedUrl} />
</ExpansionBox>
