<script>
    import localizer from "~/common/i18n.js";
    import FlashMessage from "~/components/FlashMessage.svelte";
    import Form from "~/components/Form.svelte";
    import TextInput from "~/components/TextInput.svelte";
    import Button from "~/components/Button.svelte";
    import {isSupported} from "~/common/webAuthn.js";
    import WebauthnRegistration from "@/components/webauthn/WebauthnRegistration.svelte";
    import WebauthnRegistrationComplete from "~/pages/login/WebauthnRegistrationComplete.svelte";
    import LoginBox from "~/components/LoginBox.svelte";
    import PasswordInput from "../../components/PasswordInput.svelte";
    import LinkButton from "../../components/LinkButton.svelte";
    import {onMount} from "svelte";

    export let filteredParams = {};
    export let webauthnInformationUrl = '';
    export let continueUrl;

    let webauthnRegistrationComplete = false
    export let requestPasswordChange = false;

    let webauthnIsSupported;

    function onRegistrationComplete() {
        webauthnRegistrationComplete = true;
    }

    const t = localizer({
        nb: {
            headerWebauthn: 'Lag en passnøkkel',
            infoWebauthn: 'Ta i bruk passordfri innlogging for å logge inn raskere neste gang.',
            continueWithoutPasskey: 'Fortsett uten å registrere passnøkkel',
            setPasswordInstead: 'Bytt passord',
            headerPassword: 'Lag et passord',
            infoPassword: 'Vi anbefaler at du lager et langt passord som du ikke bruker andre steder.',
            password: 'Skriv ønsket passord her:',
            button: 'Fortsett',
        },
        nn: {
            headerWebauthn: 'Lag ein passnøkkel',
            infoWebauthn: 'Tek i bruk passordfri innlogging for å logga inn raskare neste gong.',
            continueWithoutPasskey: 'Fortsett utan å registrere passnøkkel',
            setPasswordInstead: 'Bytt passord',
            headerPassword: 'Lag eit passord',
            infoPassword: 'Vi tilrår at du lagar eit langt passord som du ikkje brukar andre stader.',
            password: 'Skriv ønska passord:',
            button: 'Fortsett',
        }
    })

    onMount(() => {
        isSupported().then((supported) => {
            webauthnIsSupported = supported;
        });
    })
</script>

{#if !webauthnRegistrationComplete}
    {#if webauthnIsSupported !== undefined}
        {#if webauthnIsSupported && !requestPasswordChange}
            <LoginBox title={t('headerWebauthn')}>
                <FlashMessage />

                <p>
                    {t('infoWebauthn')}
                </p>

                <WebauthnRegistration prio={true} on:complete={onRegistrationComplete} {continueUrl} {webauthnInformationUrl} showInfoBubble={true}>Bruk passordfri innlogging</WebauthnRegistration>
                <LinkButton on:click={() => window.location.href = continueUrl}>{t('continueWithoutPasskey')}</LinkButton>
                <LinkButton on:click={() => requestPasswordChange = true}>{t('setPasswordInstead')}</LinkButton>
            </LoginBox>
        {:else}
            <LoginBox title={t('headerPassword')}>
                <FlashMessage />

                <p>
                    {t('infoPassword')}
                </p>

                <Form action="/aid/logg_inn/lagre_passord">
                    {#each Object.entries(filteredParams) as [key, value]}
                        <input type="hidden" name="{key}" value="{value}" />
                    {/each}
                    <div class="form_content">
                        <PasswordInput label={t('password')} />

                        <Button prio=true logName="enter_password">{t('button')}</Button>
                    </div>
                </Form>
            </LoginBox>
        {/if}
    {/if}
{:else}
    <WebauthnRegistrationComplete {continueUrl} />
{/if}


<style>
    @import "../../common/colors.css";

    div.form_content {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
</style>

