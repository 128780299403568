const xmlns = 'http://www.w3.org/2000/svg';

export default function createElement(name, attr = {}) {
    const el = document.createElementNS(xmlns, name);
    Object.keys(attr).forEach(key => el.setAttribute(key, attr[key]));
    if (name === 'svg') {
        el.setAttribute('xmlns', xmlns);
    }
    return el;
}

export function createElementFromHTML(str) {
    const el = new DOMParser().parseFromString(str, 'application/xml');
    return document.importNode(el.documentElement, true);
}
