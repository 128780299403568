<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import {capitalize} from "../../common/utils.js";
    import Button from "../../components/Button.svelte";

    export let vendor;
    export let profileFrontUrl;
    export let requestedUrl;

    const t = localizer({
        nb: {
            header: `Flott! Nå har du en aID`,
            info1: `Du er innlogget med {{vendor}}, og kan bruke {{vendor}} som innlogging på alle nettsteder som bruker aID for innlogging. Hvis du ønsker å bryte koblingen mellom {{vendor}} og aID, så gå til `,
            myAid: 'aID-profilen din',
            info2a: 'På',
            info2b: 'kan du endre opplysninger om deg selv og personverninnstillinger, samt se annen relevant informasjon.',
            button: 'Fortsett, du er snart i mål!'
        },
        nn: {
            header: `Flott! No har du ein aID`,
            info1: `Du er innlogga med {{vendor}}, og kan bruke {{vendor}} som innlogging på alle nettstader som bruker aID. Viss du ønskjer å bryte koplinga mellom {{vendor}} og aID, så gå til `,
            myAid: 'aID-profilen din',
            info2a: 'På',
            info2b: 'kan du endre opplysningar om deg sjølv og personverninnstillingar, og sjå annan relevant informasjon.',
            button: 'Fortsett, du er snart i mål!',
        }
    });
</script>

<LoginHeader title={t('header')} />

<article class="my-aid-pad--bottom text-block">
    <p>
        {t('info1', {vendor: capitalize(vendor)})}
        <a href={profileFrontUrl}>{t('myAid')}</a>.
    </p>
    <p>
        {t('info2a')} <a href={profileFrontUrl}>{t('myAid')}</a> {t('info2b')}
    </p>
    <Button prio={true} logName="user_created_with_external" href={requestedUrl}>{t('button')}</Button>
</article>


<style>
    article {
        display: grid;
        gap: 16px;
    }
</style>