<script>
  import localizer from "@/common/i18n.js";
  import Button from "~/components/Button.svelte";
  import Form from "@/components/Form.svelte";
  import FlashMessage from "~/components/FlashMessage.svelte";
  import LoginBox from "~/components/LoginBox.svelte";
  import UsernameInput from "../../components/UsernameInput.svelte";

  export let sendCodeUrl;
  export let filteredParams = {};
  export let codeConfirmedUrl;
  export let changeRecipientUrl;
  export let username = '';

  const t = localizer({
    nb: {
        header: 'Glemt/endre passord',
        text: 'Tast mobilnummeret ditt, så sender vi deg en engangskode på SMS. Meldingen er gratis.',
        username: 'Mobilnummer',
        button: 'Fortsett',
    },
    nn: {
        header: 'Gløymt/endre passord',
        text: 'Tast mobilnummeret ditt, så sender vi deg ein eingongskode på SMS. Meldinga er gratis.',
        username: 'Mobilnummer',
        button: 'Fortsett',
    }
  })
</script>

<LoginBox title="{t('header')}">
    <FlashMessage />
    <p>{t('text')}</p>
    <Form action="{sendCodeUrl}">
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}
        <input type="hidden" name="code_confirmed_url" value="{codeConfirmedUrl}" />
        <input type="hidden" name="change_recipient_url" value="{changeRecipientUrl}" />

        <div>
            <UsernameInput label="{t('username')}" username="{username}" />
            <Button prio="true" logName="forgot_password" type="submit">{t('button')}</Button>
        </div>
    </Form>
</LoginBox>

<style>
    div {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
</style>