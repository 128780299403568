<script>
    import localizer from "~/common/i18n.js";
    import {createEventDispatcher, onMount, tick} from "svelte";
    import {isSupported, performAutoLoginCeremony} from "../common/webAuthn.js";
    import LinkButton from "./LinkButton.svelte";
    import TextInput from "./TextInput.svelte";
    import PhoneInput from "./PhoneInput.svelte";

    export let username = '';
    export let label='Mobilnummer';

    const name='username';
    const dispatch = createEventDispatcher();

    let prioritizeMobile = true;
    let phoneInput;
    let emailInput;
    export let enableWebauthnLogin = false;
    export let postbackErrorUrl = null;

    async function handleUsernameChange(e) {
        username = e.target.value;
        if (prioritizeMobile) {
            if (e.target.value.includes('@')) {
                prioritizeMobile = false;
                await tick();
                emailInput.focus();
            }
        } else if (e.target.value.startsWith('+')) {
            prioritizeMobile = true;
            await tick();
            phoneInput.focus();
        }
    }

    onMount(async () => {
        if (enableWebauthnLogin && await isSupported()) {
            try {
                performAutoLoginCeremony(postbackErrorUrl);
            } catch (e) {
                console.error(e);
            }
        }
    });

    const t = localizer({
        nb: {
            noPhoneLink: `Jeg har ikke mobiltelefon`,
            usePhoneLink: 'Logg inn med mobiltelefon'
        },
        nn: {
            noPhoneLink: `Eg har ikkje mobiltelefon`,
            usePhoneLink: 'Logg inn med mobiltelefon'
        }
    });
</script>
{#if prioritizeMobile}
    <PhoneInput
            bind:this={phoneInput}
            {name}
            bind:value={username}
            {label}
            autocomplete="tel username webauthn"
            on:input={handleUsernameChange}
            on:change
    />
{:else}
    <TextInput
            bind:this={emailInput}
            {name}
            bind:value={username}
            type='email'
            label={label.replace('mobilnummer', 'e-post').replace('Mobilnummer', 'E-post')}
            autocomplete="email username webauthn"
            on:input={handleUsernameChange}
            on:change
    />
{/if}
<div class="toggler">
    {#if prioritizeMobile}
        <LinkButton on:click={() => {prioritizeMobile = false}}>{t('noPhoneLink')}</LinkButton>
    {:else}
        <LinkButton on:click={() => {prioritizeMobile = true}}>{t('usePhoneLink')}</LinkButton>
    {/if}
</div>

<style>
    .toggler {
        margin-top: -8px;
        display: flex;
        justify-content: right;
    }
</style>