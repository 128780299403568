<script>
    import Avatar from "./Avatar.svelte";
    import Button from "../Button.svelte";
    import MessageBox from "../MessageBox.svelte";

    export let hasAidAvatar = false;
    export let hasExternalAvatar = false;
    export let avatarUrl;
    export let confirmDeleteAvatarUrl;
    export let confirmDisconnectExternalAccountUrl;
    export let uploadUrl;
    export let redirectUrl;

    const ACCEPTED_MIMETYPES = ['image/png', 'image/jpeg'];
    const CSRFTOKEN = document.querySelector('meta[name="csrf-token"]').content;

    let fileSelector;
    let error;
    let progress = 0;

    function upload(e) {
        const body = new FormData();
        const file = fileSelector.files[0];

        if (!file) {
            error = 'Du må velge filen som skal lastes opp.';
            return;
        }
        if (file.size / 1000 > 10000) {
            error = 'Filen du prøvde å laste opp er for stor. Maks størrelse er 10 MB.';
            return;
        }
        if (ACCEPTED_MIMETYPES.indexOf(file.type) === -1) {
            error = 'Filtypen er feil. Filtypen må være enten JPG/JPEG eller PNG.';
            return;
        }

        body.append("file", file);

        const req = new XMLHttpRequest();

        req.open('post', uploadUrl);
        req.setRequestHeader('X-CSRF-Token', CSRFTOKEN);
        req.withCredentials = true;
        req.onload = () => {
            if (req.status >= 200 && req.status < 300) {
                window.location.href = redirectUrl + '?avatar=' + encodeURIComponent(req.responseText);
            } else {
                error = 'Det skjedde en feil i våre systemer. Prøv igjen senere.';
            }
        };
        req.onerror = () => error = 'Det skjedde en feil i våre systemer. Prøv igjen senere.';
        req.upload.addEventListener('progress', e => {
            progress = Math.floor((e.loaded / e.total) * 100);
        });
        return req.send(body);
    }
</script>

<div class="avatarUploader">
    <div class="aid-avatar">
      <Avatar url={avatarUrl} />
    </div>
    <input type="file" name="file" bind:this={fileSelector} accept="image/*" on:change={upload} />
    <div class="actions">
        <Button logName="avatar_upload" prio={true} on:click={() => fileSelector.click()}>Last opp bilde</Button>
        {#if hasAidAvatar}
            <a href={confirmDeleteAvatarUrl}>Fjern bildet</a>
        {:else if hasExternalAvatar}
            <a href={confirmDisconnectExternalAccountUrl}>Koble fra Facebook for å fjerne bildet</a>
        {/if}
        <progress max="100" value={progress}>{progress}%</progress>
    </div>
</div>
{#if error}
    <MessageBox type="error">{error}</MessageBox>
{/if}

<style>
    @import '../../common/colors.css';

    .avatarUploader {
        padding-left: 100px;
        display: block;
        margin: 24px 0;
    }

    .avatarUploader input {
        display: none;
    }

    .avatarUploader progress {
        appearance: none;
        color: var(--blue);
        background: var(--lightGray);
        width: 100%;
    }

    .avatarUploader progress[value="0"] {
        display: none;
    }

    .avatarUploader progress::progress-bar {
        background: var(--blue);
    }

    .avatarUploader progress::-moz-progress-bar {
        background: var(--blue);
    }

    .avatarUploader progress::-webkit-progress-bar {
        background: var(--lightGray);
    }

    .avatarUploader progress::-webkit-progress-value {
        background: var(--blue);
    }

    .avatarUploader .aid-avatar {
        margin-left: -100px;
        float: left;
        width: 80px;
        height: 80px;
    }

    .avatarUploader .aid-avatar div {
        width: 100%;
        height: 100%;
    }

    .actions {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
</style>