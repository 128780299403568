<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import {capitalize} from "../../common/utils.js";
    import Button from "../../components/Button.svelte";
    import Form from "../../components/Form.svelte";
    import ButtonBar from "../../components/ButtonBar.svelte";

    export let vendor;
    export let externalAccountName;
    export let externalLoginUrl;
    export let requestedUrl;

    const t = localizer({
        nb: {
            header: `{{vendor}}-kontoen er tilkoblet en annen aID-bruker`,
            notice: `Du er innlogget på {{vendor}} med kontoen til <b>{{externalAccountName}}</b>, og denne er koblet til en annen aID-bruker.</b>.`,
            step1: `<a target=\\"_blank\\" href=\\"https://{{vendorDomain}}/\\">Gå til {{vendor}}</a> (åpnes i ny fane) og logg på med riktig konto.`,
            step2: 'Prøv å hente profilbildet en gang til ved å trykke på knappen under:',
            buttonConfirm: 'Prøv igjen',
            buttonCancel: 'Avbryt',
        },
        nn: {
            header: `{{vendor}}-kontoen er kobla til ein annan aID-brukar`,
            notice: `aID-en med mobilnummer {{recipient}} er allereie kobla til {{vendor}}-kontoen til <b>{{otherExternalAccountName}}</b>.`,
            step1: `<a target=\\"_blank\\" href=\\"https://{{vendorDomain}}/\\">Gå til {{vendor}}</a> (åpnes i ei ny fane) og logg på med riktig konto.`,
            step2: 'Prøv å hente profilbildet ein gong til ved å trykke på knappen under:',
            buttonConfirm: 'Prøv igjen',
            buttonCancel: 'Avbryt',
        }
    })
</script>

<LoginHeader title={t('header', {vendor: capitalize(vendor)})} />

<div>
    <p>
        {@html t('notice', {vendor: capitalize(vendor), externalAccountName})}
    </p>

    <ol>
        <li>{@html t('step1', {vendor: capitalize(vendor), vendorDomain: `www.${vendor}.com`})}</li>
        <li>{t('step2')}</li>
    </ol>

    <Form action={externalLoginUrl}>
        <ButtonBar>
            <Button prio={true} logName="connect_conflict">{t('buttonConfirm')}</Button>
            <Button prio={false} href={requestedUrl}>{t('buttonCancel')}</Button>
        </ButtonBar>
    </Form>
</div>

<style>
</style>
