import '../common/index.js';
import './login.css';
import '../components/aid-text-input/index.js';
import '../components/links/what-is-aid/index.js';
import '../components/aid-button/button/index.js';
import '../components/aid-button/button-link/index.js';
import '../components/aid-button/button-bar/index.js';
import '../components/aid-non-user-vstoken-loader/index.js';
import { register } from "~/common/svelte_manager.js";
import WebauthnLoginButton from "~/components/webauthn/WebauthnLoginButton.svelte";
import WebauthnRegistrationButton from "~/components/webauthn/WebauthnRegistrationButton.svelte";
import TextInput from "~/components/TextInput.svelte";
import BirthDateInput from "~/components/BirthDateInput.svelte";
import webauthn_information from "~/pages/login/webauthn_information.svelte";
import webauthn_start_registration from "~/pages/login/webauthn_start_registration.svelte";
import webauthn_registration from "~/pages/login/webauthn_registration.svelte";
import sudomode_verify from "~/pages/sudomode/sudomode_verify.svelte";
import LoginHeader from "~/components/LoginHeader.svelte";
import SessionsListExpansionBox from "~/components/login/SessionsListExpansionBox.svelte";
import FlashMessage from "~/components/FlashMessage.svelte";
import Login from "~/pages/login/Login.svelte";
import Avatar from "~/components/profile/Avatar.svelte";
import ChangeAvatar from "../pages/profile/ChangeAvatar.svelte";
import ConfirmLogin from "~/pages/login/ConfirmLogin.svelte";
import EnterPassword from "~/pages/login/EnterPassword.svelte";
import ForceChangePassword from "~/pages/otp_credentials/ForceChangePassword.svelte";
import ForgotOrChangePassword from "~/pages/login/ForgotOrChangePassword.svelte";
import CreateAid from "~/pages/login/CreateAid.svelte";
import UserTermsCheckbox from "~/components/UserTermsCheckbox.svelte";
import ConnectExternalToAid from "../pages/login/ConnectExternalToAid.svelte";
import AidUserExists from "../pages/login/AidUserExists.svelte";
import UserConnectedToExternal from "../pages/login/UserConnectedToExternal.svelte";
import UserCreatedWithExternal from "../pages/login/UserCreatedWithExternal.svelte";
import OverwriteExternalAccount from "../pages/login/OverwriteExternalAccount.svelte";
import ExternalAccountConnectedToAnotherAid from "../pages/login/ExternalAccountConnectedToAnotherAid.svelte";
import ConfirmDisconnectExternalAccount from "../pages/profile/ConfirmDisconnectExternalAccount.svelte";
import EnterCode from "../pages/otp_credentials/EnterCode.svelte";
import TwoFactorEnterCode from "../pages/otp_credentials/TwoFactorEnterCode.svelte";
import HandleSessions from "../pages/login/HandleSessions.svelte";
import AgreementForLegalEntity from "../pages/privacy/AgreementForLegalEntity.svelte";
import IPAccess from "../pages/non_user_access/IPAccess.svelte";
register({
    webauthn_information,
    webauthn_start_registration,
    webauthn_registration,
    sudomode_verify,
    LoginHeader,
    TextInput,
    BirthDateInput,
    WebauthnRegistrationButton,
    WebauthnLoginButton,
    SessionsListExpansionBox,
    FlashMessage,
    Login,
    Avatar,
    ChangeAvatar,
    ConfirmLogin,
    EnterPassword,
    ForceChangePassword,
    ForgotOrChangePassword,
    CreateAid,
    UserTermsCheckbox,
    ConnectExternalToAid,
    AidUserExists,
    UserConnectedToExternal,
    UserCreatedWithExternal,
    OverwriteExternalAccount,
    ExternalAccountConnectedToAnotherAid,
    ConfirmDisconnectExternalAccount,
    EnterCode,
    TwoFactorEnterCode,
    HandleSessions,
    AgreementForLegalEntity,
    IPAccess,
})
