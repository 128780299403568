<script>
import WebauthnButton from "~/components/webauthn/WebauthnButton.svelte";
import localizer from "~/common/i18n.js";
import {performWebAuthnSudomodeCeremony} from "~/common/webAuthn.js";

export let prio = true;

const t = localizer({
    nb: {
        title: 'Passordfri identifikasjon',
    },
    nn: {
        title: 'Passordfri identifikasjon',
    }
})
</script>

<WebauthnButton prio={prio} logName="login_webauthn" on:click={performWebAuthnSudomodeCeremony}>{t('title')}</WebauthnButton>

<style>
</style>
