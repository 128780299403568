<script>
    import localizer from "~/common/i18n.js";
    import InfoBox from "../../components/InfoBox.svelte";
    import Button from "../../components/Button.svelte";
    import AidLogo from "../../components/AidLogo.svelte";

    export let ipAddress;
    export let loginUrl;

    const t = localizer({
        nb: {
            header: 'Velkommen!',
            info: 'Logg inn for å få tilgang til over 100 Amedia-publikasjoner når du er på din bedrifts eller organisasjons interne nettverk.',
            ipInfo: 'Din IP er: <b>{{ipAddress}}</b>. Når du har logget inn, sjekker vi om denne tilhører en bedrift/organisasjon som tilbyr IP-basert tilgang til Amedias publikasjoner.',
            login: 'Til innlogging',
            createAid: 'Hvis du ikke har en aID fra før, får du mulighet til å opprette en.',
        },
        nn: {
            header: 'Velkommen!',
            info: 'Logg inn for å få tilgang til over 100 Amedia-publikasjonar når du er på det interne nettverket i di bedrift eller organisasjon.',
            ipInfo: 'Din IP er: <b>{{ipAddress}}</b>. Når du har logga inn, sjekker vi om denne høyrer til ei bedrift/organisasjon som tilbyr IP-basert tilgang til Amedia sine publikasjonar.',
            login: 'Til innlogging',
            createAid: 'Viss du ikkje har ein aID-brukar, får du moglegheit til å opprette ein.',
        }
    });
</script>

<div class="ipaccess-ip-wrapper">
    <h2>{t('header')}</h2>
    <p>{t('info')}</p>

    <InfoBox theme="primary">
        <span class="ip-info">
            {@html t('ipInfo', {ipAddress})}
        </span>
    </InfoBox>

<Button prio={true}
        href={loginUrl}
        logName="goto_login"><span class="button"><span>{t('login')}</span><span class="logo"><span class="background"><AidLogo /></span></span></span></Button>

<p>{t('createAid')}</p>
</div>

<style>
    .ipaccess-ip-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    h2 {
        margin-bottom: 16px;
    }

    .button {
        display: flex;
        gap: 1rem;
        justify-content: center;
    }

    .logo {
        position: relative;
        width: 25px;
    }

    .logo .background {
        background-color: var(--white);
        border-radius: 100%;
        position: absolute;
        transform: translateY(-50%) scale(1.1);
        top: 50%;
        width: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        padding: 3px;
    }
</style>