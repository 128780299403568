<script>
    import localizer from "@/common/i18n.js";
    import LoginBox from "../../components/LoginBox.svelte";
    import FlashMessage from "../../components/FlashMessage.svelte";
    import Form from "../../components/Form.svelte";
    import Button from "../../components/Button.svelte";
    import SessionsListExpansionBox from "../../components/login/SessionsListExpansionBox.svelte";
    import OverlayLink from "../../components/OverlayLink.svelte";

    export let name;
    export let maxSessions;
    export let sessions;
    export let filteredParams = {};
    export let requestedUrl;
    export let termsOfServiceUrl;
    export let privacyPolicyUrl;
    export let customerServiceUrl;
    export let changePasswordUrl;
    export let familiesUrl;
    export let hasFamily;
    export let availableFamilyAllotments = 0;

    const t = localizer({
        nb: {
            header: 'Du har for mange innlogginger',
            hello: 'Hei {{name}}',
            info1: 'Av hensyn til din sikkerhet er antallet samtidige innlogginger begrenset til {{maxSessions}}.',
            info2: 'Før du kan fortsette må du redusere antall innlogginger.',
            info3: 'Din aID er personlig og skal ikke brukes av noen andre enn deg. Vi vil derfor varsle deg hver gang du har for mange innlogginger, slik at du alltid kan føle deg trygg på at gamle eller uønskede innlogginger fjernes.',
            button: 'Fjern de eldste innloggingene mine',
            changePasswordText: 'Hvis du mistenker at noen har fått tilgang til din aID-konto bør du ',
            changePasswordLink: 'endre passordet',
            familyText: 'Hvis du vil dele abonnementet ditt med andre i din husstand, kan du bruke ',
            familyAllotments: 'Du har fortsatt {{allotments}} ledige plasser.',
            noFamilyText: 'Visste du at hvis dere er flere i samme husstand, så kan dere dele abonnement ved å opprette en ',
            familyLink: 'aID Familie',
            help: 'Trenger du hjelp?',
            helpLearnMore: 'Hvis du lurer på noe kan du lese mer om',
            termsOfService: 'brukervilkår',
            privacyPolicy: 'personvern',
        },
        nn: {
            header: 'Du har for mange innloggingar',
            hello: 'Hei {{name}}',
            info1: 'Av omsyn til di tryggleik er talet på samtidige innloggingar begrensa til {{maxSessions}}.',
            info2: 'Før du kan fortsette må du redusere kor mange innloggnigar du har.',
            info3: 'Din aID er personleg og skal ikkje brukast av andre enn deg. Vi vil derfor varsle deg kvar gong du har for mange innloggingar, slik at du alltid kan kjenne deg trygg på at gamle eller uønska innloggingar vert fjerna.',
            button: 'Fjern dei eldste innloggingane mine',
            changePasswordText: 'Viss du mistenker at nokon har fått tilgang til din aID-konto bør du ',
            changePasswordLink: 'endre passordet',
            familyText: 'Viss du vil dele abonnementet ditt med andre i din husstand, kan du bruke ',
            familyAllotments: 'Du har framleis {{allotments}} ledige plassar.',
            noFamilyText: 'Visste du at viss dykk er fleire i same husstand, så kan dykk dele abonnement ved å opprette ein ',
            familyLink: 'aID Familie',
            help: 'Treng du hjelp?',
            helpLearnMore: 'Viss du lurer på noko kan du lese meir om',
            termsOfService: 'brukarvilkår',
            privacyPolicy: 'personvern',
        }
    })
</script>

<LoginBox title={t('header')}>
    <FlashMessage />

    <p><b>{t('hello', {name})}</b></p>
    <p>{t('info1', {maxSessions})}</p>
    <p>{t('info2')}</p>

    <Form action="/aid/innlogginger/rydd">
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}

        <div class="tidy_sessions">
            <Button logName="tidy_sessions_list_during_login" prio={true}>{t('button')}</Button>
        </div>
    </Form>

    <SessionsListExpansionBox {sessions} {maxSessions} {requestedUrl} />

    <p class="small-text" style="margin-top: 18px">{t('info3')}</p>
    <p class="small-text">
        {t('changePasswordText')}<a href={changePasswordUrl}>{t('changePasswordLink')}</a> ditt.
    </p>

    {#if availableFamilyAllotments > 0 || !hasFamily}
        <p class="small-text">
            {#if hasFamily}
                {t('familyText')}<a href={familiesUrl}>{t('familyLink')}</a>. {t('familyAllotments', {allotments: availableFamilyAllotments})}
            {:else}
                {t('noFamilyText')}<a href={familiesUrl}>{t('familyLink')}</a>.
            {/if}
        </p>
    {/if}

    <div class="help-me-button">
        <OverlayLink header={t('help')} label={t('help')}>
            <p>
                {t('helpLearnMore')}
                <a href={termsOfServiceUrl}>{t('termsOfService')}</a> og
                <a href={privacyPolicyUrl}>{t('privacyPolicy')}</a> på aid.no, eller kontakte oss på e-post:
                <a href={customerServiceUrl}>kundeservice@aid.no</a>.
            </p>
        </OverlayLink>
    </div>
</LoginBox>